@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$blue: #27405f;
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$orange: #d4823a;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$grey-lightest: hsl(0, 0%, 93%);

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $blue;
$link: $orange;
$navbar-breakpoint: 0;
$menu-item-color: $grey-lightest;
$menu-label-color: $grey-light;
$menu-label-font-size: 0.6rem;
$notification-padding: 1.25rem 1.25rem 1.25rem 1.25rem;
// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;

@import '../node_modules/bulmaswatch/materia/_variables.scss';
@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/bulma-pageloader/dist/css/bulma-pageloader';
@import '../node_modules/bulmaswatch/materia/_overrides.scss';
@import '~bulma-tooltip';
@import "../node_modules/bulma-o-steps/bulma-steps";
@import '../node_modules/bulma-divider/dist/css/bulma-divider';
@import '../node_modules/react-sortable-tree/style.css';
#root {
    height: 100vh;
    margin: 0; }
